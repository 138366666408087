<template>
    <div class="pa-4">
        <v-card>
            <v-card-text>
                <div class="body-1 pb-3">
                    Permissions
                </div>
                <div style="width:400px" class="">
                    <v-select v-model="role_code" placeholer="Role" :items="roles" item-text="role_name" item-value="code" filled rounded dense hide-details></v-select>
                </div>
                <div v-if="role_code">
                    <v-layout wrap>
                        <v-flex v-for="(obj, idx) in menus" :key="idx" md3 xs6>
                            <v-checkbox :label="obj.menu_name" v-model="menu_id" :value="obj.id" hide-details></v-checkbox>
                        </v-flex>
                    </v-layout>            
                    <div class="pt-3 text-right">
                        <v-btn color="primary" @click="savePermissions()">Save</v-btn>
                    </div>
                </div>
            </v-card-text>
        </v-card>
    </div>
</template>

<script>
export default {
    data() {
        return {
            role_code: null,
            menu_id: []
        }
    },
    computed: {
        roles() {
            return this.$store.state.role.objs
        },
        menus() {
            return this.$store.state.menu.objs
        }
    },
    async created() {
        await this.$store.dispatch('role/getObjs')
        await this.$store.dispatch('menu/getObjs')
    },
    methods: {
        savePermissions() {
            let payload = {
                role_code : this.role_code,
                menu_id : this.menu_id
            }
            // this.menu_id.forEach(element => {
            //     payload.push(
            //         {role_code: this.role_code, menu_id: element}
            //     )
            // });
            console.log(JSON.stringify(payload))
        }
    }
}
</script>